:root {
  --white: #ffffff;
  --yellow: #fffbf4;
  --air-grey: #f7f7f8;
  --light-grey: #ebedef;
  --light-mid-grey: #e1e3e4;
  --silver-grey: #d3d6d8;
  --mid-grey: #b7babc;
  --dark-grey: #8b8d8f;
  --dark: #0a3576;
  --light-blue: #708ab2;
  --bg: #fffbf4;
  --error: #f23a5b;
  --error-bg: #ffe4e9;

  --gradient: linear-gradient(
    133.5deg,
    #3d54a4 2.45%,
    #7789ea 50.45%,
    #94daea 98.45%
  );
  --cubic-bezier: cubic-bezier(0, 0.49, 0.25, 1);
  --transition: 0.3s ease;
  --transition-slow: 0.6s cubic-bezier(0.25, 1, 0.5, 1);

  --gotham: "Gotham Pro", sans-serif;
  --gothamMedium: "Gotham Pro Medium", sans-serif;
  --gothamBold: "Gotham Pro Bold", sans-serif;
}
