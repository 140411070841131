footer {
  width: 100%;
  // background-color: var(--bg);
  padding: min-max(0, 96) 0;

  @include mq-max-tablet {
    padding: 0;
  }

  .container {
    @include flex(row, min-max(0, 160));
    justify-content: space-between;
    padding-left: min-max(32, 160);

    @include mq-max-tablet {
      flex-direction: column-reverse;
      padding-left: 0;
    }
  }

  .footer-info {
    flex: none;
    width: min-max(375, 700);
    @include grid(2, min-max(32, 96), min-max(32, 96));

    @include mq-max-tablet {
      width: auto;
      padding-bottom: 48px;
      @include flex(column, 48px);
      align-items: center;
    }
  }

  .footer-logo {
    grid-area: 1 / 1 / 2 / 3;

    @include mq-max-tablet {
      display: none;
    }
  }

  .footer-phones {
    grid-area: 2 / 1 / 3 / 2;
  }

  .footer-address {
    grid-area: 2 / 2 / 3 / 3;
  }

  .footer-bottom {
    grid-area: 3 / 1 / 4 / 2;

    @include mq-max-tablet {
      @include flex(column, 48px);
      align-items: center;
    }
  }

  .footer-menu {
    grid-area: 3 / 2 / 4 / 3;
  }

  .footer-contacts {
    @include flex(column, 16px);

    @include mq-max-tablet {
      text-align: center;
      align-items: center;
    }

    &__heading {
      @include use-font(var(--gothamBold), 12px, 700, 160%);
      text-transform: uppercase;
      color: var(--mid-grey);
    }

    a {
      @include use-font(var(--gothamMedium), 14px, 500, 160%);
      color: var(--dark);

      @include mq-max-tablet {
        font-size: 12px;
      }
    }

    .contacts-list {
      @include flex(column, 8px);

      @include mq-max-tablet {
        align-items: center;
      }
    }

    .working-hours {
      @include use-font(var(--gothamMedium), 14px, 500, 160%);

      .day {
        color: var(--mid-grey);
        text-transform: uppercase;
      }

      .time {
        color: var(--dark);
      }
    }
  }

  .footer-socials {
    @include flex(row, 16px);

    a {
      width: 24px;
      height: 24px;
    }

    @include mq-max-tablet {
      gap: 24px;
    }
  }

  .footer-copyright {
    margin-top: 56px;
    @include use-font(var(--gothamMedium), 12px, 500, 130%);
    color: var(--dark);

    @include mq-max-tablet {
      text-align: center;
      margin: 0;
    }
  }

  .footer-menu {
    @include flex(row, min-max(16, 24));
    align-items: flex-end;

    a {
      @include use-font(var(--gothamMedium), 12px, 500, 130%);
      color: var(--mid-grey);
    }
  }

  .footer-map {
    // flex: none;
    // width: min-max(375, 982);
    width: 100%;
    height: auto;
    // height: min-max(300, 468);
    background-color: var(--air-grey);

    @include mq-max-tablet {
      width: 100%;
      aspect-ratio: 375/300;
    }
  }
}
