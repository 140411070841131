.custom-pointer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: px2vw(128);
  height: px2vw(128);
  border-radius: 50%;
  overflow: hidden;
  background: rgba(52, 71, 136, 0.3);
  backdrop-filter: blur(4px);
  font-family: var(--gothamBold);
  text-transform: uppercase;
  color: var(--white);
  font-size: px2vw(12);
  pointer-events: none;
  visibility: hidden;
}
