.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  @include use-img("../img/pattern.jpg", center, min-max(200, 256), repeat);
  background-blend-mode: multiply;
  z-index: 9999;
  overflow: hidden;

  &__logo {
    width: min-max(120, 256);
    aspect-ratio: 256/174;
    visibility: hidden;

    path {
      fill: #c8c8c8;
      stroke: #c8c8c8;
    }
  }

  &__inner {
    @include flex(column, min-max(30, 45));
    transform: translateY(min-max(100, 125));
    overflow: hidden;
  }

  &__info {
    text-align: center;
  }

  &__heading {
    @include use-font(var(--gothamBold), min-max(30, 77), 700, 110%, 0.3em);
    color: var(--dark);
    visibility: hidden;
    transform: translateY(5vh);
  }

  &__subtitle {
    @include use-font(var(--gothamBold), min-max(16, 18), 700, 130%);
    text-transform: uppercase;
    width: min-max(250, 360);
    margin: min-max(10, 20) auto 0;
    color: var(--mid-grey);
    visibility: hidden;
    transform: translateY(5vh);
  }
}
