@include font-face("Gotham Pro", "GothamPro", 400);
@include font-face("Gotham Pro Medium", "GothamPro-Medium", 500);
@include font-face("Gotham Pro Bold", "GothamPro-Bold", 700);

html,
p {
  @include use-font(var(--gotham), min-max(12, 14), 400, 160%);
}

p {
  color: var(--dark-grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h3 {
  @include use-font(var(--gothamBold), min-max(14, 16), 700, 122%);
}
