header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include flex(row);
  align-items: center;
  justify-content: space-between;
  padding: min-max(24, 32) min-max(24, 64);
  pointer-events: none;
  z-index: 1000;
  visibility: hidden;

  .header-nav {
    @include flex(row);
    align-items: center;
  }

  .header-logo {
    width: 143px;
    height: 32px;
    pointer-events: all;
    z-index: 2;

    .logo {
      @include svg-embed;
    }
  }

  .header-menu {
    @include flex(row, min-max(32));
    align-items: center;
    margin-left: min-max(134);

    @include mq-max-tablet {
      display: none;
    }

    a {
      @include use-font(var(--gothamBold), 12px, 700);
      pointer-events: all;
      color: var(--mid-grey);
      transition: color var(--transition);

      &:hover {
        color: var(--dark);
      }
    }
  }

  .header-phone {
    @include flex(row, 8px);
    align-items: center;
    margin-left: min-max(134);
    pointer-events: all;

    @include mq-max-tablet {
      display: none;
    }

    .icon {
      width: min-max(24);
      height: min-max(24);

      path {
        transition: fill 0.3s ease-in-out;
      }
    }

    .text {
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap;

      & > span {
        @include use-font(var(--gothamMedium), 14px, 500);
        transition: opacity var(--transition);
        position: absolute;
      }

      .nohover {
        color: var(--dark);
      }

      .hover {
        background: var(--gradient);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 0;
      }
    }

    &:hover {
      .text {
        .hover {
          opacity: 1;
        }
      }

      .icon {
        path {
          fill: url("#gradient-arrow");
        }
      }
    }
  }

  .header-address {
    width: 56px;
    height: 56px;
    pointer-events: all;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid var(--light-grey);
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
    }

    @include mq-max-tablet {
      display: none;
    }

    &:hover::after {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  // Mobile
  .header-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @include flex(column, 76px);
    align-items: center;
    background-color: var(--dark);
    z-index: 0;
    transform: translateX(100%);
    transition: transform var(--transition);
    pointer-events: all;
    padding: 148px 80px 20px;
    overflow-y: auto;
    text-align: center;

    .mobile-menu {
      @include flex(column, 32px);
      align-items: center;

      a {
        color: var(--white);
        @include use-font(var(--gothamMedium), 24px, 500);
        text-transform: uppercase;
      }
    }

    .mobile-info {
      @include flex(column, 40px);
      align-items: center;

      &__contacts {
        @include flex(column, 16px);
        align-items: center;
        text-align: center;

        .contacts-heading {
          @include use-font(var(--gothamMedium), 14px, 500, 160%);
          color: var(--light-blue);
          text-transform: uppercase;
        }

        a {
          @include use-font(var(--gothamMedium), 12px, 500, 130%);
          color: var(--white);
        }

        .working-hours {
          span {
            @include use-font(var(--gothamMedium), 12px, 500, 130%);
          }
          .day {
            color: var(--light-blue);
          }

          .time {
            color: var(--white);
          }
        }
      }

      &__socials {
        @include flex(row, 24px);

        a {
          width: 24px;
          height: 24px;
          svg path {
            fill: var(--light-blue);
          }
        }
      }
    }

    .mobile-copyright {
      @include use-font(var(--gotham), 10px, 400, 130%);
      color: var(--light-blue);
    }
  }

  .menu-toggle {
    @include custom-button;
    position: absolute;
    right: 8px;
    display: none;
    pointer-events: all;
    width: 48px;
    height: 48px;
    z-index: 2;

    .icon-open {
      width: 20px;
      height: 12px;
      display: flex;
    }

    .icon-close {
      width: 16px;
      height: 16px;
      display: none;
    }

    @include mq-max-tablet {
      display: flex;
    }
  }

  &.menu-opened {
    .header-mobile {
      transform: none;
    }
    .header-logo {
      path {
        fill: var(--white);
      }
    }
    .menu-toggle {
      .icon-open {
        display: none;
      }

      .icon-close {
        display: flex;
      }
    }
  }
}

// .page-template-page-services {
//   .header {
//     position: absolute;
//   }
//   .header__left {
//     width: 100%;
//     max-width: 56vw;
//   }
// }
