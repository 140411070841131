html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: clip;
  overflow-y: clip;
  @include no-scrollbar;
}

html {

  &.loaded {
    overflow-y: auto;
    height: auto;
    body {
      position: relative;
      overflow-y: auto;
      height: auto;
    }
    .body-texture {
      opacity: 1;
    }
  }
}

.body-texture {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include use-img("../img/pattern.jpg", center, min-max(156, 216), repeat);
  mix-blend-mode: multiply;
  transition: opacity 0.5s ease;
  z-index: 2;
  user-select: none;
  pointer-events: none;
}

.body-bg {
  position: fixed;
  user-select: none;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // z-index: -1;
}

main {
  visibility: hidden;
  opacity: 0;
}

section {
  position: relative;
  @include centrify-childs;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1920px;
  position: relative;
}

.flex-center {
  @include centrify-childs;
}

.svg-embed {
  @include svg-embed;
}

.img-contain {
  @include contain-img;
}

.img-cover {
  @include cover-img;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
