.fullscreen-modal {
  @include centrify-childs;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
}

.modal-gallery {
  &__content {
    width: min-max(300, 850);
    // height: min-max(774);
    aspect-ratio: 850/774;
    position: relative;

    @include mq-max-phone {
      width: 95vw;
    }
  }

  .swiper {
    width: 80%;
    height: 100%;
    // background-color: var(--white);
    z-index: 2;

    @include mq-max-phone {
      width: 100%;
    }
  }

  .slider-controls {
    @include full-bg;
    @include flex(row);
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    @include mq-max-phone {
      top: calc(100% + 60px);
      align-items: end;
      justify-content: center;
      gap: 24px;
    }
  }
}

.modal-video {
  &__content {
    width: min-max(782);
    height: min-max(440);
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.modal-contact {
  &__content {
    position: relative;
    height: 95vh;
    padding: min-max(64, 80) min-max(32, 64) min-max(24, 32) min-max(32, 64);
    @include use-img("../img/pattern.jpg", center, min-max(200, 256), repeat);
  }

  .modal-body {
    height: 100%;
    @include flex(column, min-max(12, 16));
    align-items: center;
    @include no-scrollbar;
    overflow-y: scroll;

    & > * {
      flex: none;
    }
  }

  .modal__title {
    @include use-font(var(--gothamMedium), min-max(14, 24), 500, 112%);
    color: var(--dark);
    text-transform: uppercase;
    text-align: center;
    width: 60%;
  }

  &__form {
    @include flex(column, min-max(16, 24));

    .form-select {
      margin: min-max(12, 16) auto 0;
      @include flex(row, min-max(24, 32));
      align-items: center;
      justify-content: center;

      button {
        outline: none;
        background: none;
        color: var(--dark-grey);
        font-family: var(--gothamMedium);
        border: none;
        padding-bottom: 4px;
        cursor: pointer;

        &.active {
          color: var(--dark);
          border-bottom: 2px solid var(--dark);
          pointer-events: none;
        }
      }
    }

    .form-body {
      display: none;

      &.active {
        display: block;
      }

      label {
        color: var(--dark-grey);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        @include use-font(var(--gothamMedium), min-max(12, 14), 500, 100%);
        margin-top: min-max(16, 24);
        position: relative;

        span {
          width: 100%;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &.file-upload {
          cursor: pointer;
          flex-direction: row-reverse;
          width: max-content;
          align-items: center;
          gap: 10px;
          white-space: nowrap;
          margin-left: auto;

          svg {
            flex: none;
          }
        }

        &.error {
          .tip {
            visibility: visible;
          }

          input,
          textarea {
            background-color: var(--error-bg);
            color: var(--error);
          }
        }
      }

      input,
      textarea {
        width: 100%;
        margin-top: 8px;
        padding: 12px 16px;
        background-color: var(--light-grey);
        resize: none;
        border: none;
        outline: none;
        border-radius: 8px;
        color: var(--dark);

        &::placeholder {
          color: var(--dark-grey);
        }
      }

      textarea {
        height: 100px;
      }

      input[type="file"] {
        display: none;
      }

      .tip {
        position: absolute;
        width: 100%;
        height: px2vw(16);
        left: 0;
        bottom: px2vw(-24);
        font-family: var(--gotham);
        color: var(--error);
        text-align: left;
        visibility: hidden;
      }
    }
  }

  .form-contacts {
    @include flex(column, min-max(6, 8));
    align-items: center;
  }

  &__phone,
  &__email {
    color: var(--dark);
    @include use-font(var(--gothamMedium), min-max(12, 14), 500, 160%);

    &:hover {
      background: var(--opacity);
      background: var(--gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__phone {
    font-family: var(--gothamBold);
  }

  &__social-icon {
    @include flex(row, min-max(16, 24));
    justify-content: space-around;
    // margin: 0 auto;
  }

  .social-item {
    width: min-max(24, 32);
    height: min-max(24, 32);
    @include svg-embed;
  }

  &__copyright {
    color: var(--mid-grey);
    @include use-font(var(--gotham), min-max(12, 14), 400, 100%, -0.02em);
    text-align: center;
  }

  &__img {
    width: 100%;
    aspect-ratio: 276/58;
    @include use-img("../img/modal-contact.png", center, cover, no-repeat);
  }
}

.modal-info {
  &__content {
    width: 310px;
    background-color: var(--white);
    padding: min-max(32, 40);
    @include flex(column, min-max(16, 24));
    align-items: center;
    text-align: center;
  }

  .icon {
    display: none;

    &.active {
      display: block;
    }
  }

  .modal__title {
    @include use-font(var(--gothamMedium), min-max(14, 16), 500, 112%);
    text-transform: uppercase;
    color: var(--dark);
  }
}
