[data-fade="up"] {
  opacity: 0;
  transform: translateY(50px);
}

[data-faded] {
  visibility: hidden;
  transform: translateY(75px);
}

[data-fade="in"] {
  opacity: 0;
}

[data-cover-section] {
  clip-path: inset(50%);
}

[data-section-title],
[data-reveal-text] {
  .word {
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  .char {
    position: relative;
    display: inline-block;
  }
}

[data-img-reveal] {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
