.scroll-bar {
  display: flex;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease, opacity 0.3s ease;

  .scroll-track {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    background: var(--silver-grey);
    border-radius: 2px;
    transition: transform 0.1s linear;

    &:hover {
      cursor: grab;
    }
  }

  &.body-scroll {
    height: calc(100vh - min-max(60, 80));
    width: 4px;
    bottom: min-max(12, 24);
    right: min-max(12, 24);
    border-radius: 2px;
    background: rgba(192, 195, 204, 0.3);
  }

  &.div-scroll {
    height: 100%;
    width: 2px;
    right: 0;
    top: 0;
    background-color: var(--gray-22-opacity);
    border-radius: 20px;
  }

  &.visible {
    opacity: 1;
    z-index: 9999;
    pointer-events: all;
  }

  &:hover {
    .scroll-track {
      opacity: 1;
      cursor: grab;
    }
  }

  &.is-dragging {
    cursor: grabbing;

    .scroll-track {
      opacity: 1;
      transition: none;
    }
  }
}

[data-scroller] {
  height: 100%;
  overflow-y: scroll;
  @include no-scrollbar;
}

[data-scroller-wrapper] {
  position: relative;
}

// Lenis scroll
.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
