aside {
  position: fixed;
  right: 0;
  top: min-max(120, 130);
  padding-right: min-max(24, 64);
  pointer-events: none;
  z-index: 2;
  visibility: hidden;

  @include mq-max-tablet {
    display: none;
  }

  .socials {
    width: 56px;
    @include flex(column, 16px);
    align-items: center;

    a {
      width: 24px;
      height: 24px;
      padding: 2px;
      pointer-events: all;

      svg path {
        transition: fill var(--transition);
      }

      &:hover {
        svg path {
          fill: var(--dark);
        }
      }
    }
  }
}
