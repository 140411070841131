[data-reveal-text] {
  opacity: 0;

  .word {
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  .char {
    position: relative;
    display: inline-block;
    transform: translateY(100%);
    opacity: 0;
    line-height: 1.1em;
  }

  &.splitting {
    line-height: inherit !important;
  }
}

[data-fade="up"] {
  opacity: 0;
  transform: translateY(50%);
}

[data-fade="in"] {
  opacity: 0;
}

[data-cover-section] {
  clip-path: inset(50%);
}
