@use "sass:string";

// Flex layout
@mixin flex($dir: row, $gap: 0) {
  display: flex;
  flex-direction: $dir;
  @if $gap != 0 {
    gap: $gap;
  }
}

// Grid layout
@mixin grid($num: 4, $col-gap: 0, $row-gap: 0) {
  display: grid;
  grid-template-columns: repeat($num, 1fr);
  @if $col-gap != 0 {
    column-gap: $col-gap;
  }
  @if $row-gap != 0 {
    row-gap: $row-gap;
  }
}

// Center childs
@mixin centrify-childs {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Full bg
@mixin full-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// Embeded svg
@mixin svg-embed {
  @include centrify-childs;
  svg {
    width: 100%;
    height: 100%;
  }
}

// Image cover
@mixin cover-img {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin contain-img {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@mixin custom-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

// Hide scrollbar
@mixin no-scrollbar {
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
}

// @Fontface generator
@mixin font-face($name, $file-name, $weight: 400, $style: normal, $ext: woff2) {
  $src: null;
  $path: "../fonts/";
  $src: local(quote($name));
  $src: append(
    $src,
    url(quote($path + $file-name + "." + $ext)) format(quote($ext)),
    comma
  );

  @font-face {
    font-family: quote($name);
    src: $src;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

// Font declaration
@mixin use-font($name, $size, $weight, $height: 100%, $spacing: normal) {
  font-family: $name;
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: $spacing;
}

// Background image
@mixin use-img(
  $path,
  $position: center center,
  $size: contain,
  $repeat: no-repeat
) {
  $newPath: if($env == "production", replace-extension($path, "webp"), $path);
  background: url($newPath) $position / $size $repeat;
}