.button-circle {
  @include custom-button;
  @include flex(row, min-max(10, 32));
  align-items: center;
  justify-content: center;
  width: max-content;
  height: min-max(56, 80);
  white-space: nowrap;
  transition: var(--transition);

  .b_text {
    @include use-font(var(--gothamBold), min-max(12, 14), 700, 160%);
    text-transform: uppercase;
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    transition: var(--transition);
  }

  .b_icon {
    width: min-max(56, 80);
    height: min-max(56, 80);

    .rotate,
    .play {
      transition: var(--transition);
      transform-origin: 50% 50%;
    }
  }

  &:hover {
    .b_text {
      transform: translateX(min-max(10, 16));
    }

    .b_icon {
      .rotate {
        transform: rotate(45deg) scale(0.8);
      }

      .play {
        scale: 1.4;
        transform-origin: 50% 50%;
      }
    }
  }
}

.button-arrow {
  @include custom-button;
  @include flex(row, 16px);
  align-items: center;
  width: max-content;
  height: min-max(56, 64);
  transition: var(--transition);

  .b_icon {
    transition: var(--transition);
    width: min-max(56, 64);
    height: min-max(56, 64);
  }

  .b_text {
    @include use-font(var(--gothamBold), min-max(12, 14), 700);
    text-transform: uppercase;
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    transition: var(--transition);
  }

  &:hover {
    .b_text {
      transform: translateX(min-max(8, 10));
    }

    .b_icon {
      transform: rotate(45deg) scale(0.8);
      transform-origin: 50% 50%;
    }
  }
}

.button-phone {
  @include custom-button;
  width: min-max(64, 128);
  height: min-max(64, 128);
  transition: var(--transition);

  &:hover {
    scale: 0.8;
  }

  .text-rotate {
    transform-origin: 50% 50%;
    animation: rotate 10s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.slide-nav {
  width: min-max(48, 56);
  height: min-max(48, 56);
  @include custom-button;
  @include centrify-childs;
  transition: opacity var(--transition);

  &.next-slide {
    transform: rotate(180deg);
  }

  &:hover {
    svg path {
      fill: url("#gradient-arrow");
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.modal-close {
  @include custom-button;
  @include centrify-childs;
  width: min-max(48, 56);
  height: min-max(48, 56);
  position: absolute;
  right: min-max(4, 8);
  top: min-max(4, 8);

  .icon-close {
    width: min-max(16, 24);
    height: min-max(16, 24);

    path {
      fill: var(--dark-grey);
    }
  }
}
