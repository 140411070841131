@import "index";

section.page404 {
  min-height: 100vh;
  background-color: var(--white);

  .container {
    height: 100%;
    @include grid(12, min-max(16, 40));

    @include mq-max-tablet {
      padding: 100px 0;
      @include flex(column, 48px);
      height: auto;
    }
  }

  .section-content {
    grid-area: 1 / 3 / 2 / 6;
    align-self: center;
    justify-self: left;
    @include flex(column, min-max(24, 48));
    align-items: flex-end;
    position: relative;

    @include mq-max-tablet {
      display: grid;
      width: 100%;
      height: max-content;
      padding-right: 67px;

      & > * {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }

  .section-title {
    @include use-font(var(--gothamMedium), min-max(80, 150), 500);
    color: var(--dark);
    margin-top: 1.3em;

    @include mq-max-tablet {
      font-size: 164px;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      margin-left: -0.15em;
      margin-top: 0;
    }
  }

  .section-subtitle {
    @include use-font(var(--gothamMedium), min-max(16, 28), 500, 142%);
    text-transform: uppercase;
    text-align: right;
    color: var(--dark);

    @include mq-max-tablet {
      align-self: start;
      justify-self: end;
      text-align: left;
    }
  }

  .button-arrow {
    @include mq-max-tablet {
      justify-self: end;
    }
  }

  .section-bg {
    grid-area: 1 / 6 / 2 / 12;
    align-self: end;
    aspect-ratio: 900/795;
    @include use-img("../img/404-bg.png", center bottom, contain, no-repeat);

    @include mq-max-tablet {
      width: 100%;
    }
  }
}
